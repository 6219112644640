import { WIX_FORUM as forumAppDefId } from '@wix/app-definition-ids';
import { getComponentByAppId } from '../utils/find-component';

export const setDefaultForumStyles = async (appToken, sdk) => {
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, forumAppDefId);
  const component = await getComponentByAppId(appToken, sdk, appData.applicationId);
  const compRef = await sdk.components.getById(appToken, { id: component.id });
  return sdk.document.tpa.setStyleParams(appToken, {
    compRef,
    styleParams: [
      {
        type: 'number',
        key: 'button-borderRadius',
        param: {
          value: 8,
        },
      },
      {
        type: 'number',
        key: 'post-borderRadius',
        param: {
          value: 8,
        },
      },
    ],
  });
};
