import { addApplications, withMembersArea } from '@wix/members-area-integration-kit';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';
import {
  WIX_FORUM as forumAppDefId,
  MEMBERS_AREA as membersAppDefId,
  PRICING_PLANS as pricingPlansAppDefId,
} from '@wix/app-definition-ids';
import { BM, BM_CREATE_POST } from '../constants/routes';
import { CATEGORIES, DESIGN, LAYOUT } from '../constants/app-settings-tabs';
import i18next from '../services/i18n-provider';
import { setDefaultForumStyles } from '../services/styles';
import { getComponentByAppId } from '../utils/find-component';

let appToken;
let sdk;
let t;

const CREATE_POST_ACTION = 'openCreatePost';
const MANAGE_POSTS_ACTION = 'openBusinessManager';
const EDIT_CATEGORIES_ACTION = 'openEditCategories';
const EDIT_LAYOUT_ACTION = 'openEditLayout';
const EDIT_DESIGN_ACTION = 'openEditDesign';

const addCommentsPage = () => addApplications([MA_APP_IDS.FORUM_COMMENTS]);

const upgradeForum = async () => {
  const instance = await sdk.info.getAppInstance({
    appDefinitionId: forumAppDefId,
  });

  try {
    // eslint-disable-next-line
    await fetch('https://forums.wix.com/_api/forums/this/upgrade?instance=' + instance, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    });
  } catch (error) {
    console.error('upgradeForum()', error);
  }
};

const installCommentsPageIfNeeded = async (sdk) => {
  const [isPostsPageInstalled, isCommentsPageInstalled] = await Promise.all([
    sdk.tpa.isAppSectionInstalled('forum-posts', {
      appDefinitionId: forumAppDefId,
      sectionId: 'member-posts-page',
    }),
    sdk.tpa.isAppSectionInstalled('forum-comments', {
      appDefinitionId: forumAppDefId,
      sectionId: 'member-comments-page',
    }),
  ]);

  if (isPostsPageInstalled && !isCommentsPageInstalled) {
    await addCommentsPage();
    return sdk.document.save().catch(() => {});
  }
};

const handleMigrate = () =>
  sdk.document.tpa.add.application(appToken, {
    appDefinitionId: pricingPlansAppDefId,
  });

const editorReady = async (_editorSDK, _appToken, options) => {
  appToken = _appToken;
  sdk = _editorSDK;
  await installCommentsPageIfNeeded(sdk);
  if (options.firstInstall) await setDefaultForumStyles(appToken, sdk);

  const i18n = await i18next(_editorSDK, _appToken);
  t = i18n.t.bind(i18n);
};

const handleAction = async (args) => {
  const type = args.type;
  const payload = args.payload;

  try {
    switch (type) {
      case 'appInstalled':
        switch (payload.appDefinitionId) {
          case forumAppDefId: {
            const [forumAppData = {}, allPages = []] = await Promise.all([
              sdk.tpa.app.getDataByAppDefId(appToken, forumAppDefId),
              sdk.pages.data.getAll(appToken),
            ]);

            const forumPage = allPages.find(
              (page) => page.tpaPageId === 'forum' && page.tpaApplicationId === forumAppData.applicationId,
            );

            await (forumPage &&
              sdk.document.pages.navigateTo(appToken, {
                pageLink: {
                  type: 'PageLink',
                  pageId: forumPage.id,
                },
              }));
            return sdk.document.save().catch(function () {});
          }
          case membersAppDefId: {
            const isForumInstalled = await sdk.tpa.isApplicationInstalled(appToken, {
              appDefinitionId: forumAppDefId,
            });

            if (isForumInstalled) {
              upgradeForum();
            }
          }
          // eslint-disable-next-line no-fallthrough
          default:
            return Promise.resolve();
        }

      case 'migrate':
        return handleMigrate(payload);

      default:
        console.log(type, payload);
        return Promise.resolve();
    }
  } catch (e) {
    Promise.reject();
    throw e;
  }
};

const getAppManifest = () => ({
  appDescriptor: {
    mainActions: [
      {
        title: t('App_Manager_Wix_Forum_Main_CTA'),
        actionId: CREATE_POST_ACTION,
        icon: 'appManager_addElementsAction',
      },
      {
        title: t('App_Manager_Wix_Forum_Main_CTA_2'),
        actionId: MANAGE_POSTS_ACTION,
        icon: 'appManager_settingsAction',
      },
    ],
    customActions: [
      {
        title: t('App_Manager_Wix_Forum_Quick_Action_1'),
        actionId: EDIT_CATEGORIES_ACTION,
        icon: 'appManager_settingsAction',
        type: 'editorActions',
      },
      {
        title: t('App_Manager_Wix_Forum_Quick_Action_2'),
        actionId: EDIT_LAYOUT_ACTION,
        icon: 'base-composition',
        type: 'editorActions',
      },
      {
        title: t('App_Manager_Wix_Forum_Quick_Action_3'),
        actionId: EDIT_DESIGN_ACTION,
        icon: 'customizeDesignButtonBrush',
        type: 'editorActions',
      },
    ],
    defaultActions: {
      learnMoreKB: '6dc5a0f2-c3b1-4ec4-96f4-94e70bbf3a48',
    },
  },
});

const openAppSettingsTab = async (tab) => {
  const locale = await sdk.environment.getLocale(forumAppDefId);
  const currency = await sdk.info.getCurrency(forumAppDefId);
  const appData = await sdk.tpa.app.getDataByAppDefId(appToken, forumAppDefId);
  const component = await getComponentByAppId(appToken, sdk, appData.applicationId);
  const componentRef = await sdk.components.getById(appToken, { id: component.id });
  await sdk.document.pages.navigateTo(forumAppDefId, {
    pageLink: { type: 'PageLink', pageId: component.pageId },
  });
  return sdk.editor.openComponentPanel(appToken, {
    url:
      appData.settingsUrl +
      `?instance=${appData.instance}&appDefinitionId=${appData.appDefinitionId}&applicationId=${appData.applicationId}&compId=tpaSettings&currency=${currency}&endpointType=settings&locale=${locale}&origCompId=${component.id}&viewMode=editor&tab=${tab}`,
    type: sdk.editor.PanelType.Settings,
    componentRef,
    width: 404,
    height: 528,
    title: 'Forum',
  });
};

const onEvent = async ({ eventType, eventPayload }) => {
  switch (eventType) {
    case 'appActionClicked':
      switch (eventPayload && eventPayload.actionId) {
        case CREATE_POST_ACTION:
          sdk.editor.openDashboardPanel(appToken, { url: BM_CREATE_POST, closeOtherPanels: true });
          break;
        case MANAGE_POSTS_ACTION:
          sdk.editor.openDashboardPanel(appToken, { url: BM, closeOtherPanels: true });
          break;
        case EDIT_CATEGORIES_ACTION:
          await openAppSettingsTab(CATEGORIES);
          break;
        case EDIT_LAYOUT_ACTION:
          await openAppSettingsTab(LAYOUT);
          break;
        case EDIT_DESIGN_ACTION:
          await openAppSettingsTab(DESIGN);
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }
};

export const editorScript = withMembersArea(
  {
    editorReady,
    handleAction,
    getAppManifest,
    onEvent,
  },
  {
    disableADI: false,
    membersAreaApps: [
      MA_APP_IDS.ABOUT,
      MA_APP_IDS.ALL_MEMBERS,
      MA_APP_IDS.FOLLOWERS,
      MA_APP_IDS.FORUM_COMMENTS,
      MA_APP_IDS.FORUM_POSTS,
      MA_APP_IDS.NOTIFICATIONS,
      MA_APP_IDS.SETTINGS,
    ],
  },
);
